import {
    get_authorization
} from "@/network/node/index.js";
import { get_unloginAuthorization } from "@/network/knowledge";
import config from "@/../config/index";
// console.log(config)
export function ossAuthorization() {
    const OSS = require("ali-oss");
    return get_authorization().then((res) => {
        this.client = new OSS({
            region: config.oss_region,
            accessKeyId: res.accessKeyId,
            accessKeySecret: res.accessKeySecret,
            stsToken: res.securityToken,
            refreshSTSToken: async () => {
                const info = await fetch("/file-manage-service/stsCredentials");
                return {
                    accessKeyId: info.accessKeyId,
                    accessKeySecret: info.accessKeySecret,
                    stsToken: info.securityToken,
                };
            },
            refreshSTSTokenInterval: 10000000,
            bucket: config.oss_bucket,
        });
    });
}
export function unloginOssAuthorization(params) {
    const OSS = require("ali-oss");
    return get_unloginAuthorization(params).then((res) => {
        this.client = new OSS({
            region: config.oss_region,
            accessKeyId: res.accessKeyId,
            accessKeySecret: res.accessKeySecret,
            stsToken: res.securityToken,
            refreshSTSToken: async () => {
                const info = await fetch(`/file-manage-service/knowledgeBase/public/stsCredentials/${params.pathId}?token=${params.token}`);
                return {
                    accessKeyId: info.accessKeyId,
                    accessKeySecret: info.accessKeySecret,
                    stsToken: info.securityToken,
                };
            },
            refreshSTSTokenInterval: 10000000,
            bucket: config.oss_bucket,
        });
    });
}
